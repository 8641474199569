import './ForceDirectedGraph.scss';

import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { some } from 'lodash';

import { Size } from 'utils/geometry';
import { drag } from './drag';
import { dataInFrameworks, DataLink, DataNode } from './data';

const size = new Size(1200, 700);

interface Props {
  hideOrphans: boolean;
  linkThreshold: number;
  linkStrengthMultiplier: number;
  linkStrengthExponent: number;
  repelStrength: number;
  repelMaxDistance: number;
  gravityStrength: number;
  mimumDistance: number;
  frameworks: {
    identifier: number;
    name: string;
    color: string;
  }[];
  enabledFrameworkIDs: number[];
}

const ForceDirectedGraph: React.FC<Props> = (props) => {
  const {
    hideOrphans,
    linkThreshold,
    linkStrengthMultiplier,
    linkStrengthExponent,
    frameworks,
    enabledFrameworkIDs,
    repelStrength,
    repelMaxDistance,
    gravityStrength,
    mimumDistance
  } = props;

  const svgElement = useRef<SVGSVGElement>(null);

  const scaleStrokeOpacity = useMemo(
    () => d3.scaleLinear().domain([linkThreshold, 1]).range([0, 1]),
    [linkThreshold]
  );

  const [dataNodes, dataLinks] = useMemo((): [DataNode[], DataLink[]] => {
    const [terms, relationships] = dataInFrameworks(enabledFrameworkIDs);

    const links = relationships.filter((f) => f.value >= linkThreshold);

    const nodes = hideOrphans
      ? terms.filter((term) =>
          some(
            links,
            (relationship) =>
              relationship.source === term.id || relationship.target === term.id
          )
        )
      : terms;

    return [nodes, links];
  }, [hideOrphans, linkThreshold, enabledFrameworkIDs]);

  const scaleLinkForce = useMemo(
    () => (d: DataLink) =>
      Math.pow(d.value, linkStrengthExponent) * linkStrengthMultiplier,
    [linkStrengthExponent, linkStrengthMultiplier]
  );

  const simulation: d3.Simulation<DataNode, DataLink> = useMemo(() => {
    return d3
      .forceSimulation<DataNode>()
      .force(
        'link',
        d3.forceLink().id((d: any) => d.id)
      )
      .force('charge', d3.forceManyBody())
      .force('collide', d3.forceCollide())
      .force('gravityX', d3.forceX())
      .force('gravityY', d3.forceY());
  }, []);

  // Binding Data
  useEffect(() => {
    simulation.nodes(dataNodes);
    (simulation.force('link') as d3.ForceLink<DataNode, DataLink>).links(
      dataLinks
    );
    simulation.alpha(1).restart();
  }, [dataNodes, dataLinks, simulation]);

  // Gravity
  useEffect(() => {
    if (simulation == null) {
      return;
    }

    (simulation.force('gravityX') as d3.ForceX<DataNode>)
      .strength(gravityStrength)
      .x(0);
    (simulation.force('gravityY') as d3.ForceY<DataNode>)
      .strength(gravityStrength)
      .y(0);
    simulation.alpha(1).restart();
  }, [gravityStrength, simulation]);

  // Repel
  useEffect(() => {
    (simulation.force('charge') as d3.ForceManyBody<DataNode>).strength(
      repelStrength
    );
    simulation.alpha(1).restart();
  }, [repelStrength, simulation]);

  // Repel
  useEffect(() => {
    (simulation.force('charge') as d3.ForceManyBody<DataNode>).distanceMax(
      repelMaxDistance
    );
    simulation.alpha(1).restart();
  }, [repelMaxDistance, simulation]);

  // Links
  useEffect(() => {
    (simulation.force('link') as d3.ForceLink<DataNode, DataLink>).strength(
      scaleLinkForce
    );
    simulation.alpha(1).restart();
  }, [scaleLinkForce, simulation]);

  // Collision
  useEffect(() => {
    (simulation.force('collide') as d3.ForceCollide<DataNode>)
      .radius(mimumDistance)
      .strength(1)
      .iterations(3);
    simulation.alpha(1).restart();
  }, [mimumDistance, simulation]);

  // Bind SVG
  useEffect(() => {
    if (svgElement.current == null) {
      return;
    }

    const colorForFramework = (id: number): string => {
      return frameworks.find((f) => f.identifier === id)!.color;
    };

    const svg = d3.select(svgElement.current);

    svg.selectAll('g').remove();

    const link = svg
      .append('g')
      .attr('stroke', '#9D9DAB')
      .attr('stroke-width', 2)
      .selectAll('line')
      .data(dataLinks)
      .join('line')
      .attr('stroke-opacity', (d) => scaleStrokeOpacity(d.value));

    const group = svg.append('g').attr('class', 'ForceDirectedGraph-nodes');

    const tooltip = d3.select('.ForceDirectedGraph-tooltip');

    const node = group
      .selectAll('ForceDirectedGraph-node')
      .data(dataNodes)
      .join('g')
      .attr('class', 'ForceDirectedGraph-node');

    node
      .append('circle')
      .attr('stroke', '#ffffff55')
      .attr('stroke-width', 2)
      .attr('r', 6)
      .attr('fill', (d) => colorForFramework(d.frameworkID));

    node.on('mouseenter', (event, d) => {
      const framework = frameworks.find((f) => f.identifier === d.frameworkID)!;

      tooltip
        .select('.ForceDirectedGraph-tooltipFramework')
        .text(framework.name);
      tooltip.select('.ForceDirectedGraph-tooltipName').text(d.name);
      tooltip
        .select('.ForceDirectedGraph-tooltipContent')
        .style('background-color', framework.color);

      tooltip.style('left', `${event.x}px`).style('top', `${event.y}px`);
      tooltip.classed('is-visible', true);
    });

    node.on('mousemove', (event) => {
      tooltip.style('left', `${event.x}px`).style('top', `${event.y}px`);
    });

    node.on('mouseleave', () => {
      tooltip.classed('is-visible', false);
    });

    simulation.on('tick', () => {
      link.attr('x1', (d) => d.source.x);
      link.attr('y1', (d) => d.source.y);
      link.attr('x2', (d) => d.target.x);
      link.attr('y2', (d) => d.target.y);

      node.attr('transform', (d) => `translate(${d.x}, ${d.y})`);
    });

    node.call(drag(simulation));
  }, [
    simulation,
    frameworks,
    dataLinks,
    dataNodes,
    svgElement,
    scaleStrokeOpacity,
    scaleLinkForce
  ]);

  return (
    <div className="ForceDirectedGraph">
      <svg
        className="ForceDirectedGraph-svg"
        viewBox={`${-size.width / 2} ${-size.height / 2} ${size.width} ${
          size.height
        }`}
        ref={svgElement}
        width={size.width}
        height={size.height}
      />
      <div className="ForceDirectedGraph-tooltip">
        <div className="ForceDirectedGraph-tooltipContent">
          <div className="ForceDirectedGraph-tooltipFramework"></div>
          <div className="ForceDirectedGraph-tooltipName"></div>
        </div>
      </div>
    </div>
  );
};

export default ForceDirectedGraph;

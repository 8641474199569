import { useState, useEffect } from 'react';

/**
 * Debounce a value, returning an updated value only after not being invoked for
 * at least the given delay.
 */
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useDebouncedState<T>(
  initial: T,
  delay: number
): [T, (value: T) => void, T] {
  const [value, setValue] = useState(initial);

  const debouncedValue = useDebounce(value, delay);

  return [value, setValue, debouncedValue];
}

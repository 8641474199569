import jsonData from 'data/force-directed-graph.json';

import * as d3 from 'd3';

const terms = jsonData.terms.map((d) => Object.create(d));
const relationships = jsonData.relationships.map((d) => Object.create(d));

export interface DataNode extends d3.SimulationNodeDatum {
  id: number;
  name: string;
  frameworkID: number;
}

// TODO: improve types
export interface DataLink extends d3.SimulationLinkDatum<DataNode> {
  source: any;
  target: any;
  value: number;
}

function termWithID(id: number): DataNode {
  return terms.find((term) => term.id === id)!;
}

export function dataInFrameworks(
  frameworkIDs: number[]
): [DataNode[], DataLink[]] {
  return [
    terms.filter((term) => frameworkIDs.includes(term.frameworkID)),
    relationships
      .filter(
        (relationship) =>
          frameworkIDs.includes(termWithID(relationship.source).frameworkID) &&
          frameworkIDs.includes(termWithID(relationship.target).frameworkID)
      )
      .map((d) => Object.create(d))
  ];
}

import './Controls.scss';

import React from 'react';
import { arrayTogglingElement } from 'utils/collections';

import * as Core from 'components/core';

interface Props {
  frameworks: {
    identifier: number;
    name: string;
    color: string;
  }[];
  advancedOptions: boolean;
  setAdvancedOptions(enabled: boolean): void;
  linkThreshold: number;
  setLinkThreshold(threshold: number): void;
  linkStrengthMultiplier: number;
  setLinkStrengthMultiplier(value: number): void;
  linkStrengthExponent: number;
  setLinkStrengthExponent(value: number): void;
  showOrphans: boolean;
  setShowOrphans(value: boolean): void;
  repelStrength: number;
  setRepelStregth(value: number): void;
  repelMaxDistance: number;
  setRepelMaxDistance(value: number): void;
  collideDistance: number;
  setCollideDistance(value: number): void;
  enabledFrameworkIDs: number[];
  setEnabledFrameworkIDs(value: number[]): void;
  enableAdvancedOptions: boolean;
}

const ForceDirectedGraphControls: React.FC<Props> = (props) => {
  const {
    frameworks,
    advancedOptions,
    setAdvancedOptions,
    linkThreshold,
    setLinkThreshold,
    linkStrengthMultiplier,
    setLinkStrengthMultiplier,
    linkStrengthExponent,
    setLinkStrengthExponent,
    showOrphans,
    setShowOrphans,
    repelStrength,
    setRepelStregth,
    repelMaxDistance,
    setRepelMaxDistance,
    collideDistance,
    setCollideDistance,
    enabledFrameworkIDs,
    setEnabledFrameworkIDs,
    enableAdvancedOptions
  } = props;

  return (
    <div className="ForceDirectedGraphPage-controls">
      <div>
        <h3>Frameworks</h3>
        <div className="ForceDirectedGraphPage-selectAllFrameworks">
          <Core.CheckboxField
            label="All Frameworks"
            checked={enabledFrameworkIDs.length === frameworks.length}
            indeterminate={
              enabledFrameworkIDs.length !== frameworks.length &&
              enabledFrameworkIDs.length !== 0
            }
            onChange={() => {
              setEnabledFrameworkIDs(
                enabledFrameworkIDs.length === frameworks.length
                  ? []
                  : frameworks.map((f) => f.identifier)
              );
            }}
          />
        </div>

        <div className="ForceDirectedGraphPage-rule" />

        <div className="ForceDirectedGraphPage-enabledFrameworks">
          {frameworks.map((framework) => (
            <Core.CheckboxField
              key={framework.identifier}
              label={framework.name}
              checked={enabledFrameworkIDs.includes(framework.identifier)}
              onChange={() => {
                setEnabledFrameworkIDs(
                  arrayTogglingElement(
                    enabledFrameworkIDs,
                    framework.identifier
                  )
                );
              }}
            />
          ))}
        </div>

        {!advancedOptions && (
          <Core.TextButton onClick={() => setAdvancedOptions(true)}>
            Advanced Options
          </Core.TextButton>
        )}
      </div>

      {advancedOptions && (
        <div>
          <h3>Simulation Options</h3>
          <Core.InputField label="Link Threshold">
            <Core.RangeInput
              min={0.2}
              max={1.0}
              value={linkThreshold}
              step={0.01}
              onChange={(event) =>
                setLinkThreshold(parseFloat(event.currentTarget.value))
              }
            />
          </Core.InputField>

          {enableAdvancedOptions && (
            <>
              <Core.CheckboxField
                label="Show Disconnected Nodes"
                checked={showOrphans}
                onChange={() => setShowOrphans(!showOrphans)}
              />

              <h3>Link Strength</h3>
              <Core.InputField label="Multiplier">
                <Core.RangeInput
                  min={0}
                  max={1}
                  step={0.01}
                  value={linkStrengthMultiplier}
                  onChange={(event) =>
                    setLinkStrengthMultiplier(
                      parseFloat(event.currentTarget.value)
                    )
                  }
                />
              </Core.InputField>
              <Core.InputField label="Exponent">
                <Core.RangeInput
                  min={0}
                  max={5}
                  step={0.01}
                  value={linkStrengthExponent}
                  onChange={(event) =>
                    setLinkStrengthExponent(
                      parseFloat(event.currentTarget.value)
                    )
                  }
                />
              </Core.InputField>

              <h3>Repel</h3>
              <Core.InputField label="Strength">
                <Core.RangeInput
                  min={1}
                  max={100}
                  step={0.01}
                  value={repelStrength}
                  onChange={(event) =>
                    setRepelStregth(parseFloat(event.currentTarget.value))
                  }
                />
              </Core.InputField>
              <Core.InputField label="Max Distance">
                <Core.RangeInput
                  min={10}
                  max={400}
                  step={1}
                  value={repelMaxDistance}
                  onChange={(event) =>
                    setRepelMaxDistance(parseFloat(event.currentTarget.value))
                  }
                />
              </Core.InputField>

              <h3>Collide</h3>
              <Core.InputField label="Distance">
                <Core.RangeInput
                  min={0}
                  max={20}
                  step={1}
                  value={collideDistance}
                  onChange={(event) =>
                    setCollideDistance(parseFloat(event.currentTarget.value))
                  }
                />
              </Core.InputField>

              <Core.TextButton onClick={() => setAdvancedOptions(false)}>
                Hide
              </Core.TextButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ForceDirectedGraphControls;

// Options for the force directed graph. These are extracted so differences
// between 'clone' sites with different data can be more easily separted from
// sharable components.
const config = {
  enableAdvancedOptions: true,
  linkThreshold: 0.4,
  showOrphans: false
};

export { config };

import './force-directed-graph.scss';

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useDebouncedState } from 'utils/useDebounce';

import Layout from 'components/layout/Layout';

import ForceDirectedGraph from 'components/force-directed-graph/ForceDirectedGraph';
import Controls from 'components/force-directed-graph/Controls';
import { config } from 'components/force-directed-graph/config';

interface Props {
  data: {
    allFrameworks: {
      frameworks: {
        identifier: number;
        name: string;
        color: string;
      }[];
    };
  };
}

const ForceDirectedGraphPage: React.FC<Props> = (props) => {
  const { frameworks } = props.data.allFrameworks;

  // 'Advanced Options' is a bit weird because of what we want to show on the
  // two sites. If we don't allow advanced options, we do still show the 'link
  // threshold'. This could be cleaned up.
  const [advancedOptions, setAdvancedOptions] = useState(
    !config.enableAdvancedOptions
  );

  const [linkThreshold, setLinkThreshold, debouncedLinkThreshold] =
    useDebouncedState(config.linkThreshold, 150);

  const [linkStrengthMultiplier, setLinkStrengthMultiplier] = useState(0.2);
  const [linkStrengthExponent, setLinkStrengthExponent] = useState(3);

  const [showOrphans, setShowOrphans] = useState(config.showOrphans);
  const [repelStrength, setRepelStregth] = useState(25);
  const [repelMaxDistance, setRepelMaxDistance] = useState(200);
  const [collideDistance, setCollideDistance] = useState(10);

  const [enabledFrameworkIDs, setEnabledFrameworkIDs] = useState(
    frameworks.map((f) => f.identifier)
  );

  return (
    <Layout requiresAuthentication={true}>
      <div className="ForceDirectedGraphPage">
        <ForceDirectedGraph
          hideOrphans={!showOrphans}
          frameworks={frameworks}
          enabledFrameworkIDs={enabledFrameworkIDs}
          linkThreshold={debouncedLinkThreshold}
          linkStrengthMultiplier={linkStrengthMultiplier}
          linkStrengthExponent={linkStrengthExponent}
          repelStrength={-repelStrength}
          repelMaxDistance={repelMaxDistance}
          gravityStrength={0.01}
          mimumDistance={collideDistance}
        />

        <Controls
          frameworks={frameworks}
          advancedOptions={advancedOptions}
          setAdvancedOptions={setAdvancedOptions}
          linkThreshold={linkThreshold}
          setLinkThreshold={setLinkThreshold}
          linkStrengthMultiplier={linkStrengthMultiplier}
          setLinkStrengthMultiplier={setLinkStrengthMultiplier}
          linkStrengthExponent={linkStrengthExponent}
          setLinkStrengthExponent={setLinkStrengthExponent}
          showOrphans={showOrphans}
          setShowOrphans={setShowOrphans}
          repelStrength={repelStrength}
          setRepelStregth={setRepelStregth}
          repelMaxDistance={repelMaxDistance}
          setRepelMaxDistance={setRepelMaxDistance}
          collideDistance={collideDistance}
          setCollideDistance={setCollideDistance}
          enabledFrameworkIDs={enabledFrameworkIDs}
          setEnabledFrameworkIDs={setEnabledFrameworkIDs}
          enableAdvancedOptions={config.enableAdvancedOptions}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ForceDirectedGraphQuery {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        name
        color
      }
    }
  }
`;

export default ForceDirectedGraphPage;
